<template>
  <div>
    <b-row>
      <b-col sm="9"></b-col>
      <b-col sm="3">
        <b-form-group>
          <h4>เลือก เดือน-ปี</h4>
          <v-select
            v-model="form.select"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :get-option-label="option => $date(option).format('MM-YYYY')"
            label="title"
            :options="options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- <b-button variant="warning" class="btn-icon" size="sm" @click="test">
      <feather-icon icon="ChevronLeftIcon" />
    </b-button> -->
    <b-tabs lazy @input="onTabChange">
      <b-tab title="รูปเสียตัง (รายวัน)">
        <tab-dynamic-sale-info v-if="dayInfoTable.isMain" :date="form.select" @clickView="onTableClickView" />
        <div v-else>
          <div class="d-flex align-items-center mb-1">
            <b-button variant="warning" class="btn-icon" size="sm" @click="resetDayInfoTable">
              <feather-icon icon="ChevronLeftIcon" />
            </b-button>
            <!-- <b class="m-0 ml-1">แสดงข้อมูล ณ วันที่ {{ csFormat.formatDate(dayInfoTable.date, false) }}</b> -->
            <h5 class="m-0 ml-1">
              แสดงข้อมูล ณ วันที่ {{ gFormatDate(dayInfoTable.date) }} <small>(วัน-เดือน-ปี)</small>
            </h5>
          </div>
          <tab-dynamic-sale-info
            :date="dayInfoTable.date"
            query-data-type="monthlySummaryDayDetail"
            @clickView="onTableClickView"
          />
        </div>
      </b-tab>
      <b-tab title="รูปฟรี (รายวัน)">
        <tab-dynamic-sale-info
          v-if="dayInfoTable.isMain"
          :date="form.select"
          query-data-type="monthlySummaryDayFree"
          @clickView="onTableClickView"
        />
        <div v-else>
          <div class="d-flex align-items-center mb-1">
            <b-button variant="warning" class="btn-icon" size="sm" @click="resetDayInfoTable">
              <feather-icon icon="ChevronLeftIcon" />
            </b-button>
            <!-- <b class="m-0 ml-1">แสดงข้อมูล ณ วันที่ {{ csFormat.formatDate(dayInfoTable.date, false) }}</b> -->
            <h5 class="m-0 ml-1">
              แสดงข้อมูล ณ วันที่ {{ gFormatDate(dayInfoTable.date) }} <small>(วัน-เดือน-ปี)</small>
            </h5>
          </div>
          <tab-dynamic-sale-info
            :date="dayInfoTable.date"
            query-data-type="monthlySummaryDayDetailFree"
            @clickView="onTableClickView"
          />
        </div>
      </b-tab>
      <b-tab title="รูปเสียตัง (รายเดือน)">
        <!-- <h5 class="m-0 ml-1 mb-1">แสดงข้อมูล {{ $date(form.select).format('MM-YYYY') }} <small>(เดือน-ปี)</small></h5> -->
        <tab-dynamic-sale-info :date="form.select" query-data-type="overviewMonth" @clickView="onTableClickView" />
      </b-tab>

      <!-- <b-tab title="รูปฟรี (รายเดือน)">
        <tab-dynamic-sale-info :date="form.select" query-data-type="overviewMonth" @clickView="onTableClickView" />
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    TabDynamicSaleInfo: () => import('./Tabs/TabDynamicSaleInfo.vue'),
  },
  data() {
    return {
      dayInfoTable: {
        isMain: true,
        date: null,
      },
    }
  },
  computed: {
    ...mapState('dashboardDataStore', ['options', 'form', 'loading', 'loadings']),
  },
  watch: {
    // eslint-disable-next-line func-names, space-before-function-paren
    'form.select': function() {
      this.resetDayInfoTable()
    },
  },

  created() {
    this.QUERY_DATE()
  },
  methods: {
    ...mapMutations('dashboardDataStore', ['QUERY_DATE']),
    onTableClickView(props) {
      // console.log('onTableClickView', props)
      const { row, queryDataType } = props
      if (queryDataType === 'monthlySummaryDay' || queryDataType === 'monthlySummaryDayFree') {
        this.dayInfoTable.isMain = false
        this.dayInfoTable.date = row.day
      }

      if (queryDataType === 'monthlySummaryDayDetail') {
        this.$router.push({
          name: 'wallpaper-sale-info-click-view',
          params: {
            topic_image_data_id: row.topic_image_data_id,
            date: this.dayInfoTable.date,
            queryDataType: 'monthlySummaryDayDetailListData',
          },
        })
      }

      if (queryDataType === 'monthlySummaryDayDetailFree') {
        this.$router.push({
          name: 'wallpaper-sale-info-click-view',
          params: {
            topic_image_data_id: row.topic_image_data_id,
            date: this.dayInfoTable.date,
            queryDataType: 'monthlySummaryDayDetailListDataFree',
          },
        })
      }

      if (queryDataType === 'overviewMonth') {
        this.$router.push({
          name: 'wallpaper-sale-info-click-view',
          params: {
            topic_image_data_id: row.topic_image_data_id,
            date: this.form.select,
            queryDataType: 'overviewMonthStep2',
          },
        })
      }
    },
    resetDayInfoTable() {
      this.dayInfoTable = {
        isMain: true,
        date: null,
      }
    },
    async test(date, topicImageDataId) {
      const resp = await this.api.post('api/report/sale-info/index', {
        queryDataType: 'monthlySummaryDayDetailListData',
        date,
        topic_image_data_id: topicImageDataId,
      })

      // console.log('test', resp)
    },
    onTabChange(tabIndex) {
      if (tabIndex === 2 && !this.dayInfoTable.isMain) {
        this.resetDayInfoTable()
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
